<template>
<div class="taskbag animate__animated animate__fadeInDown">
    <!-- <div class="header">
        <a-icon class="back" @click="$router.go(-1)" type="left" />
        <span class="title">Taskbag</span>
    </div> -->
    <a-spin v-if="loading" />
    <div v-else>
        <pkgitem :baginfo="bag" size="b" />
        <div class="flex prices" v-if="bag.prices">
            <div class="btn" v-for="p,i in bag.prices" :key="'bagprice'+i"  :class="bagPriceIdx==i?'btn-primary':'btn-primary-ghost'" @click="bagPriceIdx=i">{{p}}</div>
        </div>
        <div class="btn btn-primary join" @click="buybag()">
            {{$t('tasks.join')}}
        </div>
        <a-row type="flex" justify="space-around">
            <a-col :span="12" v-for="g,i in goods" :key="'g'+i" class=" goods-item flex column">
                <img class="goods-image" :src="g.fimg" height="100" alt="">
                <div class="title">{{g.title}}</div>
                <div class="price">{{g.price + $t('common.currency')}}</div>
            </a-col>
        </a-row>
        <div>
            {{$t('tasks.andsoon',{count: count>1000?count:(1000+count)})}}
        </div>
    </div>
</div>
  
</template>
<script>
import Pkgitem from '../components/Pkgitem.vue';
export default {
  name: "Taskbag",
  data() {
    return {
      loading: false,
      bag_id: this.$route.params.id,
      bag:{
        prices:[]
      },
      goods:[],
      bagPriceIdx: 0,
      count:1000
    };
  },
  components:{
    Pkgitem
  },
  mounted() {
    this.getData();
    // this.$store.commit('setShowLogo', false);
    // this.$store.commit('setShowNav', false);
    this.getGoodsList();
  },
  methods: {
    getData(){
        if(!this.bag_id || !/^\d+$/.test(this.bag_id)){
          this.$router.push({name:'home'});
          return;
        }
        this.loading = true;
        this.$http.call(this.$http.api.bag,{
            data:{id: this.bag_id}
        }).then(res=>{
            // console.log(res);
            this.loading = false;
            let resp = res.data;
            if(resp.code=='200'){
                this.bag = resp.data;
                this.bag.prices = this.bag.prices.reverse();
            }else{
                this.$message.error(resp.data);
            } 
            
        },function(res){
            console.log('something goes wrong...');
            console.log(res);
        }).then(()=>{
            
        });
    },
    getGoodsList(){
        let _this = this
        this.$http.call(this.$http.api.goodsList,{
            data: {bag_id: this.bag_id}
        }).then(function(res){
            // console.log(res.data.data.content)
            _this.goods = res.data.data.data;
            _this.count = res.data.data.total;
        },function(res){
            console.log('something goes wrong...');
            console.log(res);
        }).then(()=>{
            
        });
        
    },
    buybag(){
        if(!this.bag_id || !/^\d+$/.test(this.bag_id)){
          this.$router.push({name:'home'});
          return;
        }
        this.loading = true;
        let _data = {bid: this.bag_id,bag_price: this.bag.prices.length-1-this.bagPriceIdx};
        this.$http.call(this.$http.api.buybag,{
            data:_data
        }).then(res=>{
            console.log(res);
            this.loading = false;
            let resp = res.data;
            if(resp.code=='200'){
                // this.bag = resp.data;
                this.$router.push({name:'task',params:{id: resp.data.id}})
                console.log(200)
            }else{
                this.$message.error(resp.data);
            } 
            
        },function(res){
            console.log('something goes wrong...');
            console.log(res);
        }).then(()=>{
            
        });
    }
  },
};
</script>
<style lang="less" scoped>
.taskbag{
    padding: 30px 15px;
    width: 100%;
    .prices{
        padding: 15px;
        margin-bottom: 15px;
        justify-content: center;
        .btn{
            padding: 8px 15px;
            margin:3px;
        }
    }
    .join{
        margin: 0 15px 15px;
    }
    .goods-item{
        margin: 15px 0;
        .goods-image{
            width: 100px;
        }
        .title{
            height: 2.4rem;
            overflow:hidden;
        }
    }
    
}

</style>
