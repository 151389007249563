<template>
    <div class="package-item" @click="$router.push({name:'bag',params:{id:baginfo.id}})">
        <img class="package-bg" :src="baginfo.bgimg" alt="">
        <div class="pkgtype">
            <a-icon v-if="baginfo.type==0" type="folder-open" />
            <a-icon v-else type="folder" />
            {{$t('tasks.taskTypes')[baginfo.type]}}
        </div>
        <div class="package-info" :class="size">
            <img class="icon" :src="baginfo.iconimg" alt="">
            <div class="flex column info">
                <div class="title">{{baginfo.title}}</div>
                <div>period: {{baginfo.period}} DAY</div>
                <div>Reap profit: <span class="profit">{{baginfo.rate}}%</span></div>
                <!-- <div>{{baginfo.amount}}LIRA ({{baginfo.period}} DAY)</div> -->
                <!-- <div>Reap profit: <span class="profit">{{baginfo.amount*baginfo.rate/100}}LIRA</span></div> -->
                
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Pkgitem',
        data(){
            return {}
        },
        props: {
            baginfo: {
                type: Object
            },
            size: {
                type: String,
                default: ()=>{
                    return '';
                }
            }
        },
        
    }
</script>

<style scoped lang="less">
.package-item{
    width: 100%;
    position: relative;
    font-size: 12px;
    color: #fff;
    padding: 40px 15px;
    // margin-bottom: 10px;
    .package-bg{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
    }
    .pkgtype{
        position: absolute;
        top: 10px;right:15px;
        color: #ccc;
    }
    .package-info{
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        .icon{
            width: 40px;
            padding-right: 10px;
        }
        .info{
            align-items: flex-start;
            .title{
                font-size: 1.2rem;
                font-weight: bold;

            }
            .profit{
                color: @primary-color;
                font-weight: bold;
            }
        }
        
    }.b{
        justify-content: space-between;
        padding: 0 20px 0 15px;
    }
}
</style>